@font-face {
  font-family: "Gotham-Ultra";
  src: url("./assets/fonts/Gotham-Ultra.ttf");
}

@font-face {
  font-family: "Gotham-Medium";
  src: url("./assets/fonts/Gotham-Medium.otf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

figure {
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
  height: 100%;
  -webkit-user-drag: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p,
li,
input {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
  margin: 0;
  border: none;
  outline: none;
}

ul {
  width: 100%;
  padding: 0;
  margin: 0;
}

button {
  display: flex;
  align-items: center;
  padding: 25px;
  height: 55px;
  box-sizing: border-box;
  border-radius: 28px;
  background: #323946;
  color: white;
  border: none;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  outline: none;
  cursor: pointer;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

li,
li a {
  list-style-type: none;
  text-decoration: none;
  color: white;
  box-sizing: border-box;
}

a:focus,
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

/* HIDE INPUT NUMBER ARROWS */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
/* HIDE INPUT NUMBER ARROWS */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: white;
  box-shadow: 0 0 0 30px white inset !important;
}

/* SLIDER */
.carousel-root {
  width: 100%;
}

.carousel.carousel-slider {
  border-radius: 10px;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.4);
}

.slider-wrapper {
  height: 350px;
  border-radius: 10px;
}

.slider-wrapper img {
  width: 600px;
  height: 350px;
  object-fit: contain;
}

.thumb {
  background: white;
  width: 90px !important;
  height: 90px;
  border: 3px solid #99a7bf !important;
  border-radius: 5px;
}

.thumb.selected {
  border: 3px solid #323946 !important;
}

.carousel .thumb img {
  object-fit: contain;
}

.carousel .slide img {
  max-height: 100%;
  object-fit: contain;
}

.slide {
  padding: 0;
}
/* SLIDER */

/* CALENDAR */
.calendar {
  font-family: "Roboto";
  color: white;
  font-size: 15px;
  font-weight: 500;
  padding: 15px;
}

.calendar-month_header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 15px;
  font-size: 19px;
  font-weight: 700;
}

.calendar-header_button {
  width: 35px;
  height: 35px;
  background: #99a7bf;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 20px;
}

.calendar-days_of_week {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 35px 0 30px 0;
  font-size: 14px;
  color: #99a7bf;
}

.calendar-week {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 5px 0;
}

.calendar-week button {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.is-next_month,
.is-prev_month {
  color: #99a7bf;
}

.is-today {
  background: #99a7bf;
  color: #323946;
  font-weight: 700;
}
/* CALENDAR */

/* ANIMATIONS */
@keyframes errorAlert {
  from {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(-10px, 0px);
  }
  75% {
    transform: translate(10px, 0px);
  }
  to {
    transform: translate(0px, 0px);
  }
}

@keyframes errorAlertFixed {
  from {
    transform: translate(-50%, -50%);
  }
  25% {
    transform: translate(-55%, -50%);
  }
  75% {
    transform: translate(-45%, -50%);
  }
  to {
    transform: translate(-50%, -50%);
  }
}
/* ANIMATIONS */
